import React, { useState } from "react";
import {
  Button,
  Divider,
  Form,
  Modal,
  Checkbox,
  Popconfirm,
  Empty,
  Input,
} from "antd";
import {
  PlusOutlined,
  MessageOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import TodoImage from "../../assets/EmptyTodoImage.png";

const mapStateToProps = (store) => {
  const ProfileReducer = store?.ProfileReducer;

  return { ProfileReducer };
};

const TodoList = ({ ProfileReducer }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchTodo, setFetchTodo] = useState(false);
  const { loading, todoList } = ProfileReducer;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...todoList?.reduce((result, item) => {
        if (item?.status !== "1") {
          result[item.id] = true;
          return result;
        } else {
          result[item.id] = false;
          return result;
        }
      }, {}),
    });
  }, [todoList]);

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "todos",
          attempt: "FETCH_TODOLIST_REQUEST_ATTEMPT",
          success: "FETCH_TODOLIST_REQUEST_SUCCESS",
          failure: "FETCH_TODOLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, [fetchTodo]);

  const handleDeleteTodoItem = (value) => {
    dispatch(
      allActions(
        { todo_id: value?.id, status: value?.status },
        {
          method: "post",
          endPoint: "todos/delete",
          attempt: "DELETE_TODOLIST_REQUEST_ATTEMPT",
          success: "DELETE_TODOLIST_REQUEST_SUCCESS",
          failure: "DELETE_TODOLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            const newList = todoList.filter((item) => item.id !== value.id);
            dispatch({ type: "UPDATE_TODO_LIST", payload: newList });
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleUpdateTodoList = (item) => {
    dispatch(
      allActions(
        { todo_id: item?.id, status: Number(item?.status === "1" ? "0" : "1") },
        {
          method: "post",
          endPoint: "todos/status",
          attempt: "UPDATE_TODOLIST_REQUEST_ATTEMPT",
          success: "UPDATE_TODOLIST_REQUEST_SUCCESS",
          failure: "UPDATE_TODOLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            const newList = todoList.map((itm) => {
              if (itm.id === item.id) {
                if (itm?.status === "1") {
                  return { ...itm, status: "0" };
                } else {
                  return { ...itm, status: "1" };
                }
              } else {
                return itm;
              }
            });

            dispatch({ type: "UPDATE_TODO_LIST", payload: newList });
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div className="todo--container">
      <div className="todo--container--header">
        <h3>Todo Task List</h3>
        <Button
          size="small"
          onClick={() => setIsModalOpen(true)}
          className="todo--add--button"
        >
          <PlusOutlined />
          Todo
        </Button>
        <TodoAddModal
          setFetchTodo={setFetchTodo}
          readonly={loading}
          loading={loading}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
      <Divider style={{ margin: "0" }} />
      <div className="todo--container--body">
        {todoList?.length > 0 ? (
          <Form
            form={form}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {todoList?.map((item) => (
              <Form.Item
                valuePropName="checked"
                key={item}
                name={item?.id}
                wrapperCol={{
                  span: 24,
                }}
                style={{ margin: "0" }}
                labelAlign="left"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "0 1rem",
                  }}
                >
                  <Checkbox
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onClick={() => handleUpdateTodoList(item)}
                    checked={item?.status === "1" ? true : false}
                    value={item}
                    className={item?.status === "1" ? "cross--label" : ""}
                  >
                    {item?.task}
                  </Checkbox>
                  <Popconfirm
                    title="Do you want to delete todo element?"
                    onConfirm={() => handleDeleteTodoItem(item)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined className="delete--todo--element" />{" "}
                  </Popconfirm>
                </div>
              </Form.Item>
            ))}
          </Form>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.2rem",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(TodoList);

export const TodoAddModal = ({
  isModalOpen,
  setIsModalOpen,
  loading,
  setFetchTodo,
}) => {
  const dispatch = useDispatch();

  const handlePostTask = (values) => {
    dispatch(
      allActions(values, {
        method: "post",
        endPoint: "todos/add",
        attempt: "ADD_TODO_REQUEST_ATTEMPT",
        success: "ADD_TODO_REQUEST_SUCCESS",
        failure: "ADD_TODO_REQUEST_FAILURE",

        saveBearerToken: false,
        successInternalState: () => {
          setIsModalOpen(false);
          setFetchTodo((prev) => !prev);
        },
        failureInternalState: () => {},
      })
    );
  };

  return (
    <Modal
      title="Add Todo task"
      open={isModalOpen}
      closable={false}
      footer={null}
    >
      <Form onFinish={handlePostTask}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Comment is Required",
            },
          ]}
          name="todos"
        >
          <Input.TextArea
            className="comment--input"
            prefix={<MessageOutlined />}
            placeholder="Comment*"
            rows={6}
          />
        </Form.Item>

        <div className="todo--container--footer">
          <Button loading={loading} htmlType="submit">
            {loading ? "Loading..." : "Add Task"}
          </Button>
          <Button danger onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
