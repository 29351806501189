import React, { useEffect, useState, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, connect } from "react-redux";
import { Card, Divider, Modal, Timeline } from "antd";
import { SettingOutlined, UserSwitchOutlined } from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import {
  FeatureProperty,
  ActivityAdView,
  TodoList,
  QuickInquiry,
} from "../../component";
import { allActions } from "../../Redux/myActions";
import { PieChart } from "../../component/charts/PieChart";
import OffersMessage from "../../component/offerMessage/OffersMessage";
import LatestProperty from "../../component/insight/LatestProperty";
import PieChartSkeleton from "../../skeleton/insight/PieChartSkeleton";
import { GiTwoCoins } from "react-icons/gi";
import { timeValueFormatter } from "../../Helpers/FrontendHelper";

const mapStateToProps = (store) => {
  const { PropertyReducer, ProfileReducer } = store;
  return { PropertyReducer, ProfileReducer };
};

const Insight = ({ PropertyReducer, ProfileReducer }) => {
  const dispatch = useDispatch();
  const { changetodoloading, myActivity } = ProfileReducer;
  const { loading, realEstateData } = PropertyReducer;
  useEffect(() => {
    window.scrollTo(0, { behavior: "smooth" });
  }, []);

  useLayoutEffect(() => {
    // dispatch(
    //   allActions(
    //     {},
    //     {
    //       method: "get",
    //       endPoint: "realstate_profile",
    //       attempt: "REALESTATE_PROFILE_REQUEST_ATTEMPT",
    //       success: "REALESTATE_PROFILE_REQUEST_SUCCESS",
    //       failure: "REALESTATE_PROFILE_REQUEST_FAILURE",

    //       saveBearerToken: false,
    //       successInternalState: () => {},
    //       failureInternalState: () => {},
    //     }
    //   )
    // );
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            // localStorage.setItem("is_agent", res?.is_agent);
          },
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const [soldRented, setSoldRented] = useState([]);
  const [offerInquiry, setOfferInquiry] = useState([]);
  const [sellRent, setSellRent] = useState([]);
  const [AdStatus, setAdStatus] = useState([]);

  useEffect(() => {
    // if (PropertyReducer?.realEstateData) {
    //   const product_details =
    //     PropertyReducer?.realEstateData?.realestate_detail;

    setOfferInquiry([
      {
        type: "Inquiry",
        value: 1,
      },
    ]);

    setAdStatus([
      {
        type: "Pending",
        value: 1,
      },
      {
        type: "Decline",
        value: 1,
      },
      {
        type: "Accepted",
        value: 1,
      },
    ]);

    setSellRent([
      {
        type: "Hired",
        value: 12,
      },
      {
        type: "Accepted",
        value: 12,
      },
    ]);

    setSoldRented([
      {
        type: "Sold Out",
        value: 1,
      },
      {
        type: "Rented",
        value: 1,
      },
      {
        type: "Remain",
        value: 1,
      },
    ]);
    // }
  }, []);

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
  ];

  return (
    <DashboardLayout>
      <div className="dashboard--container">
        <Breadcrumb title="DashBoard" crumbList={crumbList} />
        <div className="notification--container">
          <h3
            style={{
              flex: 1,
              fontWeight: 500,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            You have{" "}
            <span
              style={{
                color: "orange",
                display: "flex",
                flexDirection: "row",
                gap: "0.2rem",
                alignItems: "center",
                margin: "0 0.5rem",
              }}
            >
              <GiTwoCoins style={{ fontSize: "2rem" }} />{" "}
              <span style={{ color: "#0b3e75ce", fontWeight: 600 }}>
                {realEstateData?.classi_credit}
              </span>
            </span>{" "}
            Coins available in your Wallet.
          </h3>
          <div className="notification--container--body">
            <NavLink
              to="https://classibazaar.com.au/notification_setting_list"
              className="button--modal--primary"
              style={{ gap: "0.5rem" }}
            >
              <SettingOutlined /> {`Notification Settings`}
            </NavLink>
            <NavLink
              to="/referral/invite-friend"
              className="button--modal--primary"
              style={{ gap: "0.5rem" }}
            >
              <UserSwitchOutlined /> {`  Invite Friends`}
            </NavLink>
          </div>
        </div>
        {/* {loading ? (
          <PieChartSkeleton />
        ) : ( */}
        <div className="piechart--property--container">
          {AdStatus && <PieChart data={AdStatus} />}
          {/* {soldRented && <PieChart data={soldRented} />} */}
          <PieChart data={sellRent} />
          {offerInquiry && <PieChart data={offerInquiry} />}
        </div>
        {/* )} */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <ActivityAdView />
        </div>
        {/* <div className="todo--offermessage--container"> */}
        {/* <OffersMessage /> */}
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <TodoList />

          <div className="activityadview--card">
            <h3 style={{ padding: "1rem" }}>My Activity Log</h3>
            <Divider style={{ margin: "0" }} />
            <Timeline
              className="overflow--scroll"
              style={{
                maxHeight: "19rem",
                overflowY: "scroll",
                padding: "1rem",
              }}
              items={[
                ...myActivity?.map((item) => {
                  return {
                    children: (
                      <div
                        style={{
                          color: "slategrey",
                          fontWeight: 600,
                        }}
                      >
                        <span>{item?.title}</span>
                        <p style={{ fontWeight: 400, fontSize: "12px" }}>
                          {timeValueFormatter(item?.created_date)}s
                        </p>
                      </div>
                    ),
                  };
                }),
              ]}
            />{" "}
          </div>
        </div>
        {/* </div> */}
        {/* <LatestProperty /> */}
        <QuickInquiry />
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Insight);
