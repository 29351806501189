import {
  Avatar,
  Button,
  Descriptions,
  Divider,
  Drawer,
  Empty,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import AddSupportTicket from "./AddSupportTicket";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";
import moment from "moment";
import AddSupportReply from "./AddSupportReply";

export default function SupportTicketDetail({
  isDrawerOpen,
  setIsDrawerOpen,
  support,
}) {
  const { supportDetails, loading } = useSelector(
    (store) => store?.AgentAgencyReducer
  );
  const dispatch = useDispatch();
  const [openReply, setOpenReply] = useState(false);

  useEffect(() => {
    fetchSupportTypeCategoryList();
  }, []);

  const fetchSupportTypeCategoryList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `support/${support?.id}`,
          attempt: "FETCH_SUPPORT_DETAILS_REQUEST_ATTEMPT",
          success: "FETCH_SUPPORT_DETAILS_REQUEST_SUCCESS",
          failure: "FETCH_SUPPORT_DETAILS_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
      }}
      width={1100}
      title="Support"
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            padding: "1rem",
            borderRadius: "1rem",
            border: "1px solid #cfcfcf30",
          }}
        >
          <Skeleton />
        </div>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 3fr",
            gap: "1rem",
            width: "100%",
            padding: "0 1.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "1rem",
              borderRadius: "1rem",
              border: "1px solid #cfcfcf30",
              maxHeight: "30rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <span>Requestor</span>
              <span>
                {supportDetails?.support?.first_name}{" "}
                {supportDetails?.support?.last_name}
              </span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",

                justifyContent: "space-between",
              }}
            >
              <span>Problem Type</span>
              <span>{supportDetails?.support?.type}</span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <span>Problem Category</span>
              <span>{supportDetails?.support?.category}</span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <span>Submitted</span>
              <span>
                {" "}
                {moment(supportDetails?.support?.gmt)
                  .local()
                  .format("DD/MM/YYYY hh-mm a")}
              </span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <span>Priority</span>
              <span>High</span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <span>Status</span>
              <span>{supportDetails?.support?.status}</span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <span>Message</span>
              <span>{supportDetails?.support?.message}</span>
            </div>
            <Divider style={{ margin: "0.5rem 0" }} />
            <Button onClick={() => setOpenReply(true)} type="primary">
              REPLY
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "1rem",
              borderRadius: "1rem",
              border: "1px solid #cfcfcf30",
            }}
          >
            {supportDetails?.comments?.length > 0 ? (
              supportDetails?.comments?.map((comment) => (
                <LeadComment comment={comment} />
              ))
            ) : (
              <Empty />
            )}
          </div>
        </div>
      )}
      {openReply && (
        <AddSupportReply
          fetchSupportTypeCategoryList={fetchSupportTypeCategoryList}
          supportId={support?.id}
          openReply={openReply}
          setOpenReply={setOpenReply}
        />
      )}
    </Drawer>
  );
}

export const LeadComment = ({ comment }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        borderRadius: "1rem",
        gap: "0.5rem",
        border: "1px solid #cfcfcf50",
        background: "#fcfcfc",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        {comment?.thumbnail ? (
          <img
            src={comment?.thumbnail}
            alt="thumbnail"
            style={{
              width: "3rem",
              height: "3rem",
              borderRadius: "100%",
              objectFit: "contain",
            }}
          />
        ) : (
          <Avatar
            style={{
              backgroundColor: "blue",
              width: "2.5rem",
              height: "2.5rem",
              borderRadius: "100%",
            }}
            size="large"
          >
            {comment?.first_name && comment?.first_name[0]}
          </Avatar>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>
            {comment?.first_name} {comment?.last_name}
          </h3>
          <span>
            {moment(comment?.gmt).local().format("DD MMMM YYYY hh-mm a")}
          </span>
        </div>
      </div>
      <p>{comment?.message}</p>
    </div>
  );
};
