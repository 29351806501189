import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, message } from "antd";
import { useDispatch, connect } from "react-redux";

import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classileads.png";
import AddServiceModal from "./AddServiceModal";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const AgentRequest = (AuthReducer) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const { loading } = AuthReducer;

  const handleSubmitAgentRequest = (values) => {
    dispatch(
      allActions(
        {
          email: localStorage.getItem("userEmail"),
          password: localStorage.getItem("userCode"),
        },
        {
          method: "post",
          endPoint: "authenticate",
          attempt: "LOGIN_REQUEST_ATTEMPT",
          success: "LOGIN_REQUEST_SUCCESS",
          failure: "LOGIN_REQUEST_FAILURE",

          saveBearerToken: true,
          successInternalState: (res) => {
            dispatch(
              allActions(
                {
                  company_abn: values?.abnNumber,
                  company_name: values?.companyName,
                  module_id: 6,
                  app_name: "CLASSILEADS",
                },
                {
                  method: "post",
                  endPoint: "agency_request",
                  attempt: "AGENCY_REQUEST_ATTEMPT",
                  success: "AGENCY_REQUEST_SUCCESS",
                  failure: "AGENCY_REQUEST_FAILURE",

                  saveBearerToken: false,
                  successInternalState: (res) => {
                    message.success(res?.message);
                    // navigate("/");
                    // localStorage.clear();
                    setInterval(() => {
                      setOpenModal(true);
                    }, 1500);
                  },
                  failureInternalState: (res) => {
                    message.warning(res?.message);
                  },
                }
              )
            );
          },
          failureInternalState: () => {},
        }
      )
    );
    // dispatch(
    //   allActions(
    //     {
    //       company_abn: values?.abnNumber,
    //       company_name: values?.companyName,
    //       module_id: 6,
    //       app_name: "CLASSILEADS",
    //     },
    //     {
    //       method: "post",
    //       endPoint: "agency_request",
    //       attempt: "AGENCY_REQUEST_ATTEMPT",
    //       success: "AGENCY_REQUEST_SUCCESS",
    //       failure: "AGENCY_REQUEST_FAILURE",

    //       saveBearerToken: false,
    //       successInternalState: (res) => {
    //         message.success(res?.message);
    //         navigate("/");
    //       },
    //       failureInternalState: (res) => {
    //         message.warning(res?.message);
    //       },
    //     }
    //   )
    // );
  };

  return (
    <div className="verifyotp--container">
      <div className="verifyotp--container--body">
        {loading && <LoaderPrimary />}

        <Form
          layout="vertical"
          className="form--container"
          onFinish={handleSubmitAgentRequest}
        >
          <img
            src={logo3}
            style={{
              width: "17rem",
              height: "auto",
              cursor: "pointer",
            }}
            alt="classiEstate Logo"
            onClick={() => {
              navigate("/");
            }}
          />
          <p
            className="login--header"
            style={{ fontWeight: "semibold", marginBottom: "1rem" }}
          >
            Agent Request
          </p>

          <Form.Item
            className="login--input"
            name="companyName"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Company is required</span>
                ),
              },
            ]}
          >
            <Input
              className="login--input login--email"
              size="large"
              placeholder="Company Name"
            />
          </Form.Item>

          <Form.Item
            className="login--input"
            name="abnNumber"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    ABN number is required
                  </span>
                ),
              },
            ]}
          >
            <Input
              className="login--input login--email"
              size="large"
              type="number"
              placeholder="ABN Number"
            />
          </Form.Item>

          <button
            disabled={loading}
            type="submit"
            style={{
              outline: "0",
              border: "0",
              margin: 0,
              borderRadius: "1rem !important",
              margin: "0",
              width: "100%",
              fontSize: "1rem",
            }}
            className="login--button"
          >
            Submit{" "}
          </button>
        </Form>
      </div>

      {openModal && (
        <AddServiceModal openModal={openModal} setOpenModal={setOpenModal} />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(AgentRequest);
