import { Button, Modal } from "antd";
import React from "react";
import { Checkbox, Divider } from "antd";

export default function AddServiceModal({ openModal, setOpenModal }) {
  const CheckboxGroup = Checkbox.Group;

  const plainOptions = ["cat1", "cat2", "cat3", "cat5"];

  return (
    <Modal
      style={{
        top: 20,
      }}
      open={openModal}
      closable={false}
      footer={false}
      width={800}
    >
      <h1 style={{ textAlign: "center" }}>Select the inquiry service</h1>
      <div style={{ height: "80vh" }}>
        <CheckboxGroup
          style={{
            display: "grid",
            margin: "1rem 0",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            gap: "1rem",
            width: "100%",
          }}
          options={plainOptions}
          //   value={checkedList}
          //   onChange={onChange}
        />
        <Button style={{ alignSelf: "end" }} type="primary">
          {" "}
          Submit
        </Button>
      </div>
    </Modal>
  );
}
